"use client";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { GoogleLogin } from "@/components/molecules/login-redirects";

import { signInWithEmailAndPassword } from "firebase/auth";
import { firebase } from "@aether/shared/lib/firebase/firebase.client";
import Link from "next/link";

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});

export const LoginForm = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function signIn(email: string, password: string) {
    try {
      const res = await signInWithEmailAndPassword(
        firebase.auth,
        email,
        password,
      );
    } catch (e) {
      console.log(e);
    }
  }

  function onSubmit(data: z.infer<typeof FormSchema>) {
    void signIn(data.email, data.password);
  }

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-4 p-3 space-y-4 border border"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className={""}>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type={"email"} {...field} className={"w-full"} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className={""}>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input type={"password"} {...field} className={"w-full"} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className={"w-full"}>
            Submit
          </Button>
        </form>
      </Form>
      <div className="flex items-center gap-4 py-1">
        <div className="h-px flex-1 bg-primary" />
        <p className={"text-md"}>OR</p>
        <div className="h-px flex-1 bg-primary" />
      </div>

      <section className="w-full flex flex-col gap-1">
        <GoogleLogin />
        <small className="mt-2 text-center">
          Don&lsquo;t have an account? Sign up now!
        </small>
        <Button asChild className="flex items-center">
          <Link href="/register">Register</Link>
        </Button>
      </section>
    </div>
  );
};
