"use client";
import { redirect } from "next/navigation";
import { useAuth } from "@aether/shared/context/auth";
import LandingCardInfo from "@/components/molecules/landing-card-info";
import HomePageSearchForm from "@/components/organisms/landing-page-dummy-search";
import { useEffect } from "react";
import TimelineComponent from "@/components/molecules/timeline-component";
import PreSignUpForm from "@/components/organisms/presignup-form";

export default function Home() {
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (user && !isLoading) {
      return redirect("/home");
    }
  }, [user, isLoading]);

  return (
    <main>
      <div className="container flex pt-10 pb-5 mx-auto min-h-screen">
        <div className="w-full grid">
          <h1 className="text-6xl font-bold leading-snug text-aether-blue text-center">
            Discover, Capture, Inspire
            <br />
            with AetherLenz
          </h1>
          <h3 className="text-lg md:text-2xl  leading-loose text-neutral-800 text-center">
            Look for photography captains, ask for inspiring shots, and find
            your photos here in seconds.
          </h3>
          <HomePageSearchForm />
          <div className="grid sm:grid-cols-3 items-end justify-center gap-3">
            <LandingCardInfo
              url="/images/landing-page/register.jpg"
              title="Sign up for free!"
            />
            <LandingCardInfo
              url="/images/landing-page/photographer.jpg"
              title="Approach our photography captains"
            />
            <LandingCardInfo
              url="/images/landing-page/searched-photo.jpg"
              title="Find photos with your face."
            />
          </div>
        </div>
      </div>
      <div className="container p-10 flex flex-col min-h-screen lg:flex-row my-4 items-end">
        <div id="presignup">
          <h1 className="text-5xl font-semibold leading-snug text-aether-blue">
            Capture More, Earn More!
            <br />
            Join our network today!
          </h1>
          <h3 className="text-xl leading-loose text-neutral-800 my-6 px-3">
            We help attractions/organizers and skilled photographers sell their
            photos to visitors with the help of Artificial Intelligence (AI).
          </h3>
          <h4 className="text-amber-400 text-2xl font-semibold my-6">
            How it works
          </h4>
          <div className="pl-7 max-w-2xl text-justify">
            <TimelineComponent
              order={1}
              description="For organizer, create a collection folder and invite your chosen photographer as contributor."
            />
            <TimelineComponent
              order={2}
              description="Inform your visitors about AetherLenz and its AI face recognition capability, ask them to sign up, and share your organizer’s code."
              note="Note: the code is useful for your visitors to quickly search uploaded photos."
            />
            <TimelineComponent
              order={3}
              description="Capture creatively and upload the photos and your logo(s) for brand visibility."
              note="Advise: Also enable our free photo enhancement feature to deliver enhanced photo version to your visitors."
            />
            <TimelineComponent
              order={4}
              description="Visitors can search their photos with their face. And ask them to buy their most inspiring shots in high-quality."
              note="Note: Visitors will receive both high-quality photos and our enhanced version if you enable our free enhancement feature."
            />
          </div>
        </div>
        <div className="min-w-md max-w-lg h-fit w-full border p-6 rounded-lg shadow-sm mx-auto">
          <PreSignUpForm />
        </div>
      </div>
    </main>
  );
}
