export default function TimelineComponent({
  order,
  description,
  note,
}: {
  order: number;
  description: string;
  note?: string;
}) {
  return (
    <div className="border-l border-dashed border-aether-blue relative px-10 pt-1 pb-10 border-spacing-24 ">
      <div className="aspect-ratio h-14 w-14 text-2xl text-white bg-aether-blue rounded-full flex items-center justify-center text-center absolute -left-7 top-0">
        {order}
      </div>
      <p>{description}</p>
      {note ? <i className="font-light">{note}</i> : null}
    </div>
  );
}
